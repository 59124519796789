@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #111827;
    @apply text-slate-100;
}


h1 {
    @apply font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-sky-400 to-cyan-300;
    /*bg-gradient-to-r from-sky-400 to-cyan-300*/
}



.copy {
    @apply w-32 font-bold text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2
}

.refresh {
    @apply w-32 font-bold text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2
}
